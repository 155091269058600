$theme-colors: (
    "info": #64bcff,
    "danger": #D81E5B,
    "warning": #FEC10B,
    "success":#028425,
    "primary":#035CB5,
    "secondary": #5E6973,
    "light-secondary": #F2F5FF,
    "light-primary":#eeeeee,
    "light-muted": #eeeeee,
    "black": #010400,
    "pink": #FF858D,
    "grey": #4c4c4c,
    "diselected-primary": #8ac4ff,
    "neon-green": #60e760
);

@import '~bootstrap/scss/bootstrap.scss';