.xcd-container {
    margin-left: 12rem !important;
}


.xcd-container-large {
    /* min-width: 90%; */
    margin-left: 9rem !important;
    margin-right: 3rem !important;
    border-radius: 0rem 0rem 1rem 1rem;
}

.xcn-container {
    margin-left: 9rem !important;
    margin-right: 3rem !important;
    border-radius: 0rem 0rem 1rem 1rem;

}

.xcn-user-profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}